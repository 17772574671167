import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { API_BASE_URL } from "../apiConfig";
import logo from "../logo.jpg"; // Adjust the path to your logo image
import Footer from "../components/Footer";

function LoginPage() {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "#edfcf9",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "20px",
    },
    input: {
      padding: "10px",
      borderRadius: "5px",
      border: "1px solid #052423",
      marginBottom: "10px",
      outline: "none",
      "&:focus": {
        borderColor: "#45d831",
        boxShadow: "0 0 0 2px rgba(0,95,115,0.2)",
      },
    },
    button: {
      backgroundColor: "#45d831",
      color: "#edfcf9",
      border: "none",
      borderRadius: "5px",
      padding: "10px 20px",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#9dee17",
      },
    },
    logo: {
      width: "350px", // Set the logo size
      marginBottom: "10px",
    },
    caption: {
      color: "#052423",
      fontSize: "16px",
      marginBottom: "20px",
      textAlign: "center",
    },
    linkButton: {
      backgroundColor: "transparent",
      color: "#052423",
      textDecoration: "none",
      textAlign: "center",
      padding: "10px 20px",
      borderRadius: "5px",
      border: `1px solid #45d831`,
      "&:hover": {
        backgroundColor: "#9dee17",
        color: "#edfcf9",
      },
      margin: "10px", // Increase space between login and signup
    },
    footer: {
      marginTop: "20px",
      color: "#90ab9b",
      fontSize: "12px",
    },
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${API_BASE_URL}/api/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem("token", data.token);
        console.log("Navigating to dashboard...");
        navigate("/dashboard");
      } else {
        console.error("Login response:", response.status, response.statusText);
        console.error("Login failed:", data.message);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  return (
    <div style={styles.container}>
      <img src={logo} alt="GPTEval Logo" style={styles.logo} />
      <div style={styles.caption}>
        Grade with Confidence: Fast, Fair, and Secure
      </div>
      <form onSubmit={handleLogin} style={styles.form}>
        <input
          type="email" // Change type to email for appropriate validation
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          required
          placeholder="Email"
          style={styles.input}
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
          style={styles.input}
        />
        <button type="submit" style={styles.button}>
          Login
        </button>
      </form>
      <Link to="/signup" style={styles.linkButton}>
        Sign Up
      </Link>
      <Footer />
    </div>
  );
}

export default LoginPage;

import React, { useState, useEffect, useCallback, useRef } from "react";
import { Spinner, Alert, Button } from "react-bootstrap";
import Latex from "react-latex-next";
import "katex/dist/katex.min.css";
import axios from "axios";
import { API_BASE_URL } from "../apiConfig";
import { useParams, useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function StepThree({ latexContent }) {
  const { classId, examId } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [renderedPdfUrl, setRenderedPdfUrl] = useState("");
  const [streamedResponses, setStreamedResponses] = useState([]);
  const [isPdfGenerated, setIsPdfGenerated] = useState(false);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const isMounted = useRef(false); // Ref to track component mount status

  const handleGenerateAndUploadPdf = useCallback(async () => {
    console.log("Starting PDF generation and upload...");
    setLoading(true);
    setError("");
    try {
      // Adding delay to ensure content is fully rendered
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Adjust the delay as needed

      const input = document.getElementById("pdfContent");
      console.log("pdfContent element:", input);
      const pdf = new jsPDF();
      const margin = 10;
      const pageHeight = pdf.internal.pageSize.height - 2 * margin;
      const pageWidth = pdf.internal.pageSize.width - 2 * margin;

      const elements = Array.from(input.children);
      let currentHeight = 0;

      for (const element of elements) {
        console.log("Processing element:", element);
        const canvas = await html2canvas(element, { backgroundColor: null });
        const imgData = canvas.toDataURL("image/png");

        console.log("Image data:", imgData.substring(0, 100)); // Log the first 100 characters of the image data

        const imgProps = pdf.getImageProperties(imgData);
        let imgWidth = pageWidth;
        let imgHeight = (imgProps.height * imgWidth) / imgProps.width;

        if (imgHeight > pageHeight) {
          imgHeight = pageHeight;
          imgWidth = (imgProps.width * imgHeight) / imgProps.height;
        }

        if (currentHeight + imgHeight > pageHeight) {
          pdf.addPage();
          currentHeight = 0;
        }

        pdf.addImage(
          imgData,
          "PNG",
          margin,
          currentHeight + margin,
          imgWidth,
          imgHeight
        );
        currentHeight += imgHeight;

        if (element.tagName === "HR") {
          pdf.addPage();
          currentHeight = 0;
        }
      }

      const pdfBlob = pdf.output("blob");
      console.log("Generated PDF blob:", pdfBlob);
      const formData = new FormData();
      formData.append("pdf", pdfBlob, "rendered.pdf");

      console.log("Uploading rendered PDF...");
      const uploadResponse = await axios.post(
        `${API_BASE_URL}/exams/class/${classId}/exam/${examId}/upload-rendered-pdf`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setRenderedPdfUrl(uploadResponse.data.pdfUrl);
      console.log(
        "Rendered PDF uploaded successfully:",
        uploadResponse.data.pdfUrl
      );

      setIsPdfGenerated(true); // Set flag to true after successful upload
    } catch (error) {
      console.error("Error generating PDF:", error);
      setError("Failed to generate rendered PDF. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [classId, examId, token]);

  const fetchAndStreamResponses = useCallback(async () => {
    console.log("Fetching GPT responses...");
    setLoading(true);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/exams/class/${classId}/exam/${examId}/generate-pdf`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setStreamedResponses(response.data.gptResponses);
      setPdfUrl(response.data.pdfUrl);
      console.log("GPT responses fetched successfully");

      await handleGenerateAndUploadPdf(); // Generate and upload the rendered PDF after fetching responses
    } catch (error) {
      console.error("Failed to fetch GPT responses:", error);
      setError("Failed to fetch GPT responses. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [classId, examId, token, handleGenerateAndUploadPdf]);

  useEffect(() => {
    if (!isMounted.current) {
      fetchAndStreamResponses();
      isMounted.current = true; // Set the ref to true after the initial mount
    }
  }, [fetchAndStreamResponses]);

  return (
    <div className="step">
      <h2>Review and Download</h2>
      <div id="pdfContent">
        {streamedResponses.map((response, index) => (
          <React.Fragment key={index}>
            <div>
              {response.split("\n").map((line, i) => (
                <p key={i}>
                  <Latex>{line}</Latex>
                </p>
              ))}
            </div>
            <hr />
          </React.Fragment>
        ))}
      </div>
      {loading && (
        <div className="loading-container">
          <Spinner animation="border" />
          <span>Your responses are being generated, please wait.</span>
        </div>
      )}
      {error && <Alert variant="danger">{error}</Alert>}
      {isPdfGenerated && (
        <Button
          onClick={() => window.open(renderedPdfUrl, "_blank")}
          disabled={loading}
        >
          Download Rendered PDF
        </Button>
      )}
      <Button
        onClick={() => navigate("/dashboard")}
        disabled={loading}
        style={{ marginTop: "20px" }}
      >
        Back to Dashboard
      </Button>
    </div>
  );
}

export default StepThree;

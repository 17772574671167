import React from "react";
import { Button, ListGroup, InputGroup, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinusCircle,
  faFileDownload,
  faCamera,
} from "@fortawesome/free-solid-svg-icons";
import { addExam, updateExam, deleteExam } from "../pages/apiUtils"; // Ensure this path is correct

const ExamManagement = ({ classId, exams, token, updateClassState }) => {
  const handleExamNameChange = async (examId, newName) => {
    if (!newName.trim()) {
      alert("Exam name cannot be empty");
      return;
    }
    try {
      const updatedExam = await updateExam(
        classId,
        examId,
        { name: newName },
        token
      );
      updateClassState(classId, updatedExam, "update");
    } catch (error) {
      console.error("Failed to update exam name:", error);
    }
  };

  const handleDeleteExam = async (examId) => {
    if (
      window.confirm(
        "Are you sure you want to delete this exam? This action cannot be undone."
      )
    ) {
      try {
        await deleteExam(classId, examId, token);
        updateClassState(classId, examId, "delete");
      } catch (error) {
        console.error("Error deleting exam:", error);
      }
    }
  };

  const handleAddExam = async () => {
    const newExam = {
      name: "New Exam",
      modelImages: [],
      studentSubmissions: [],
      resultPdfUrl: "",
      resultExcelUrl: "",
    };
    try {
      const addedExam = await addExam(classId, newExam, token);
      updateClassState(classId, addedExam, "add");
    } catch (error) {
      console.error("Failed to add exam:", error);
    }
  };

  return (
    <>
      <ListGroup variant="flush">
        {exams.map((exam, idx) => (
          <ListGroup.Item key={idx}>
            <InputGroup>
              <FormControl
                defaultValue={exam.name}
                onBlur={(e) => handleExamNameChange(exam._id, e.target.value)}
                aria-label="Exam name"
              />
              <Button
                variant="outline-success"
                onClick={() =>
                  (window.location.href = `/class/${classId}/upload/${exam._id}`)
                }
              >
                <FontAwesomeIcon icon={faCamera} /> Grade
              </Button>
              <Button
                variant={
                  exam.resultPdfUrl ? "outline-info" : "outline-secondary"
                }
                onClick={() => {
                  if (exam.resultPdfUrl) {
                    window.open(exam.resultPdfUrl, "_blank");
                  }
                }}
                disabled={!exam.resultPdfUrl}
              >
                <FontAwesomeIcon icon={faFileDownload} /> PDF
              </Button>
              {/*
              <Button
                variant="outline-info"
                onClick={() => (window.location.href = `/results/${exam._id}`)}
              >
                <FontAwesomeIcon icon={faTable} /> Excel
              </Button>
              */}
              <Button
                variant="outline-danger"
                onClick={() => handleDeleteExam(exam._id)}
              >
                <FontAwesomeIcon icon={faMinusCircle} />
              </Button>
            </InputGroup>
          </ListGroup.Item>
        ))}
      </ListGroup>
      <Button variant="primary" onClick={handleAddExam}>
        <FontAwesomeIcon icon={faPlus} /> Add Exam
      </Button>
    </>
  );
};

export default ExamManagement;

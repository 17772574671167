import React from 'react';

function Footer() {
  const styles = {
    footer: {
      marginTop: '20px',
      color: '#90ab9b',
      fontSize: '12px',
    }
  };

  return (
    <div style={styles.footer}>
      © {new Date().getFullYear()} GPTEval 1.65. All rights reserved.
    </div>
  );
}

export default Footer;

import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Alert } from "react-bootstrap";
import Footer from "../components/Footer";
import axios from "axios";
import { API_BASE_URL } from "../apiConfig";

function BillingForm() {
  const stripe = useStripe();
  const elements = useElements();
  const [credits, setCredits] = useState(20); // Starting with 20 credits
  const [pricePerCredit, setPricePerCredit] = useState(0.5);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Determine price per credit based on the volume
  const handleCreditsChange = (selectedCredits) => {
    setCredits(selectedCredits);
    if (selectedCredits >= 300) {
      setPricePerCredit(0.3);
    } else if (selectedCredits >= 120) {
      setPricePerCredit(0.35);
    } else if (selectedCredits >= 60) {
      setPricePerCredit(0.4);
    } else {
      setPricePerCredit(0.45);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      console.log("Stripe.js has not loaded yet.");
      return;
    }
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log("[error]", error);
      setError("Payment failed. Please try again.");
    } else {
      console.log("PaymentMethod", paymentMethod);
      // Handle the result and send it to your server
      try {
        setLoading(true);
        const token = localStorage.getItem("token"); // Get the user's token
        const response = await axios.post(
          `${API_BASE_URL}/billing/charge`,
          {
            paymentMethodId: paymentMethod.id,
            credits,
            pricePerCredit,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          // Update user credits
          // Optionally: fetch the latest user data to update the UI
          alert("Payment successful");
        } else {
          setError("Failed to process payment. Please try again.");
        }
      } catch (err) {
        console.error("Payment error:", err);
        setError("Failed to process payment. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  const styles = {
    button: {
      backgroundColor: "#45d831",
      color: "#edfcf9",
      border: "none",
      borderRadius: "5px",
      padding: "10px 20px",
      margin: "10px 5px",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#9dee17",
      },
    },
    cardSection: {
      padding: "20px",
      borderRadius: "5px",
      border: "1px solid #ccc",
      marginBottom: "20px",
      width: "100%",
      maxWidth: "400px",
    },
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#edfcf9",
      }}
    >
      <h2>Buy Credits</h2>
      <div>
        <button style={styles.button} onClick={() => handleCreditsChange(20)}>
          20 Credits
        </button>
        <button style={styles.button} onClick={() => handleCreditsChange(60)}>
          60 Credits
        </button>
        <button style={styles.button} onClick={() => handleCreditsChange(120)}>
          120 Credits
        </button>
        <button style={styles.button} onClick={() => handleCreditsChange(300)}>
          300 Credits
        </button>
      </div>
      <div>
        <p>
          {credits} Credits at {pricePerCredit.toFixed(2)}€ per credit
        </p>
        <p>Total: {(credits * pricePerCredit).toFixed(2)}€</p>
      </div>
      <form
        onSubmit={handleSubmit}
        style={{ width: "100%", maxWidth: "400px" }}
      >
        <div style={styles.cardSection}>
          <CardElement options={{ style: { base: { fontSize: "18px" } } }} />
        </div>
        <button
          type="submit"
          style={styles.button}
          disabled={!stripe || loading}
        >
          {loading ? "Processing..." : "Pay Now"}
        </button>
      </form>
      {error && <Alert variant="danger">{error}</Alert>}
      <Footer />
    </div>
  );
}

export default BillingForm;

import React from "react";
import { Row, Col } from "react-bootstrap";
import ImageInputCard from "../components/ImageInputCard";
import TooltipButton from "../components/TooltipButton";

function StepTwo({
  studentImages,
  onAddStudentImage,
  onUpload,
  onDeleteImage,
  onAddStudent,
}) {
  const handleImageChange = (studentIndex, imageIndex, file) => {
    const updatedImages = [...studentImages];
    if (!updatedImages[studentIndex]) updatedImages[studentIndex] = [];
    updatedImages[studentIndex][imageIndex] = file;
    onAddStudentImage(updatedImages);
  };

  const handleDelete = (studentIndex, imageIndex) => {
    const updatedImages = [...studentImages];
    const imageUrl = updatedImages[studentIndex][imageIndex];
    onDeleteImage(imageUrl, "student", studentIndex); // Call onDeleteImage with imageUrl, type, and studentIndex
    updatedImages[studentIndex].splice(imageIndex, 1);
    if (updatedImages[studentIndex].length === 0) {
      updatedImages.splice(studentIndex, 1);
    }
    onAddStudentImage(updatedImages);
  };

  return (
    <div className="step">
      <h2>Upload Student Exams</h2>
      {studentImages.map((images, studentIndex) => (
        <Row key={studentIndex}>
          <h5>Student {studentIndex + 1}</h5>
          {images.map((image, imageIndex) => (
            <Col key={imageIndex}>
              <ImageInputCard
                image={image}
                onImageUpload={(file) =>
                  handleImageChange(studentIndex, imageIndex, file)
                }
                onDelete={() => handleDelete(studentIndex, imageIndex)}
              />
            </Col>
          ))}
          <Col>
            <TooltipButton
              tooltip="Add another exam for this student"
              onClick={() =>
                handleImageChange(studentIndex, images.length, null)
              }
              icon="plus"
            />
          </Col>
        </Row>
      ))}
      <TooltipButton
        tooltip="Add a new student"
        onClick={onAddStudent}
        icon="user-plus" // FontAwesome user-plus icon
      />
      <TooltipButton
        tooltip="Upload all images"
        onClick={onUpload}
        icon="upload"
      />
    </div>
  );
}

export default StepTwo;

import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { API_BASE_URL } from '../apiConfig';
import logo from '../logo.jpg'; // Adjust the path
import Footer from '../components/Footer';

function SignUpPage() {
  let navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#edfcf9',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px',
    },
    input: {
      padding: '10px',
      borderRadius: '5px',
      border: '1px solid #052423',
      marginBottom: '10px',
      outline: 'none',
      '&:focus': {
        borderColor: '#45d831',
        boxShadow: '0 0 0 2px rgba(0,95,115,0.2)',
      }
    },
    button: {
      backgroundColor: '#45d831',
      color: '#edfcf9',
      border: 'none',
      borderRadius: '5px',
      padding: '10px 20px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#9dee17',
      }
    },
    logo: {
      width: '350px', // Set the logo size
      marginBottom: '10px',
    },
    caption: {
      color: '#052423',
      fontSize: '16px',
      marginBottom: '20px',
      textAlign: 'center',
    },
    checkboxLabel: {
      display: 'flex',
      alignItems: 'center',
    },
    checkbox: {
      marginRight: '10px', // Added space between checkbox and label text
    },
    linkButton: {
      backgroundColor: 'transparent',
      color: '#052423',
      textDecoration: 'none',
      textAlign: 'center',
      padding: '10px 20px',
      borderRadius: '5px',
      border: `1px solid #45d831`,
      '&:hover': {
        backgroundColor: '#9dee17',
        color: '#edfcf9',
      },
      margin: '10px',
    },
    footer: {
      marginTop: '20px',
      color: '#90ab9b',
      fontSize: '12px',
    }
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    if (!agreeToTerms) {
      alert("You must agree to the terms and conditions to sign up.");
      return;
    }
    if (email !== confirmEmail) {
      alert("Emails do not match!");
      return;
    }
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem('token', data.token);
        navigate('/dashboard');
      } else {
        console.error('SignUp response:', response.status, response.statusText);
        console.error('SignUp failed:', data.message);
      }
    } catch (error) {
      console.error('Network error:', error);
    }
  };

  return (
    <div style={styles.container}>
      <img src={logo} alt="GPTEval Logo" style={styles.logo} />
      <form onSubmit={handleSignUp} style={styles.form}>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required style={styles.input} />
        <input type="email" value={confirmEmail} onChange={(e) => setConfirmEmail(e.target.value)} placeholder="Confirm Email" required style={styles.input} />
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required style={styles.input} />
        <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm Password" required style={styles.input} />
        <label style={styles.checkboxLabel}>
          <input type="checkbox" checked={agreeToTerms} onChange={(e) => setAgreeToTerms(e.target.checked)} style={styles.checkbox} />
          I agree to the&nbsp;<Link to="/terms">terms and conditions</Link>.
        </label>
        <button type="submit" style={styles.button}>Sign Up</button>
      </form>
      <Link to="/login" style={styles.linkButton}>Already have an account? Log In</Link>
      <Footer />
    </div>
  );
}

export default SignUpPage;

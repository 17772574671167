import React from "react";
import { Row, Col } from "react-bootstrap";
import ImageInputCard from "../components/ImageInputCard";
import TooltipButton from "../components/TooltipButton";

function StepOne({ modelImages, onAddModelImage, onDeleteImage, onNext }) {
  const handleImageChange = (index, file) => {
    const updatedImages = [...modelImages];
    updatedImages[index] = file;
    onAddModelImage(updatedImages);
  };

  const handleDelete = (index) => {
    const updatedImages = [...modelImages];
    const imageUrl = updatedImages[index];
    onDeleteImage(imageUrl, "model"); // Call onDeleteImage with imageUrl and type
    updatedImages.splice(index, 1);
    onAddModelImage(updatedImages);
  };

  return (
    <div className="step">
      <h2>Please capture the exam model</h2>
      <Row>
        {modelImages.map((image, index) => (
          <Col key={index}>
            <ImageInputCard
              image={image}
              onImageUpload={(file) => handleImageChange(index, file)}
              onDelete={() => handleDelete(index)}
            />
          </Col>
        ))}
        <Col>
          <TooltipButton
            tooltip="Add another model image"
            onClick={() => handleImageChange(modelImages.length, null)}
            icon="plus"
          />
        </Col>
      </Row>
      <TooltipButton
        tooltip="Proceed to the next step"
        onClick={onNext}
        icon="arrow-right"
      />
    </div>
  );
}

export default StepOne;

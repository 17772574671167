import React, { useState, useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";

function ImageInputCard({ image, onImageUpload, onDelete }) {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (typeof image === "string") {
      setImageUrl(image);
    } else if (image) {
      const url = URL.createObjectURL(image);
      setImageUrl(url);
      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [image]);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setImageUrl(url);
      onImageUpload(file);
    }
  };

  return (
    <Card
      style={{
        border: "1px solid grey",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {imageUrl ? (
        <>
          <img
            src={imageUrl}
            alt="Uploaded"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
          <Button
            variant="danger"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              borderRadius: "50%",
            }}
            onClick={() => {
              onDelete();
              if (typeof imageUrl !== "string") {
                URL.revokeObjectURL(imageUrl);
              }
              setImageUrl(null);
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </>
      ) : (
        <>
          <label
            htmlFor="file-input"
            style={{
              cursor: "pointer",
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon
              icon={faPlus}
              size="3x"
              style={{ color: "grey" }}
            />
          </label>
          <input
            id="file-input"
            type="file"
            accept="image/*"
            capture="environment"
            onChange={handleFileSelect}
            style={{ display: "none" }}
          />
        </>
      )}
    </Card>
  );
}

export default ImageInputCard;

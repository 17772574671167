// apiUtils.js
// Import the API base URL from apiConfig.js
import { API_BASE_URL } from '../apiConfig';

export const fetchClassesAndCredits = async (token) => {
    console.log(API_BASE_URL);
    console.log(token);
    const response = await fetch(`${API_BASE_URL}/api/user/details`, {
        headers: {
            'Authorization': `Bearer ${token}`
        },
    });
    if (!response.ok) throw new Error('Failed to fetch user details');
    return response.json();
};

export const createClass = async (className, token) => {
    const response = await fetch(`${API_BASE_URL}/api/classes`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ name: className }),
    });
    if (!response.ok) throw new Error('Failed to create class');
    return response.json();
};

export const updateClass = async (classId, newName, token) => {
    console.log(`Updating class ${classId} with new name: ${newName}`);
    const response = await fetch(`${API_BASE_URL}/api/classes/${classId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ name: newName })  // Ensure this is correctly sending a JSON object with a name property
    });
    const data = await response.json();
    if (!response.ok) {
        console.error('Failed to update class:', data);
        throw new Error('Failed to update class');
    }
    console.log('Class updated successfully:', data);
    return data;
};



export const deleteClass = async (classId, token) => {
    const response = await fetch(`${API_BASE_URL}/api/classes/${classId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
    if (!response.ok) throw new Error('Failed to delete class');
    return response.json();
};

export const addExam = async (classId, examData, token) => {
    console.log(`Attempting to add exam to class ID: ${classId}`);
    console.log(`Exam data being sent:`, examData);
    try {
        const response = await fetch(`${API_BASE_URL}/api/classes/${classId}/exams`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(examData)
        });
        if (!response.ok) {
            const errorData = await response.json();
            console.error('Failed to add exam:', errorData);
            throw new Error('Failed to add exam');
        }
        const data = await response.json();
        console.log('Exam added successfully:', data);
        return data;
    } catch (error) {
        console.error('Error in addExam function:', error);
        throw error;
    }
};



export const updateExam = async (classId, examId, examData, token) => {
    console.log(`Updating exam ${examId} in class ${classId}`);
    const response = await fetch(`${API_BASE_URL}/api/classes/${classId}/exams/${examId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(examData)
    });
    if (!response.ok) {
        const errorData = await response.text();
        console.error('Failed to update exam:', errorData);
        throw new Error('Failed to update exam');
    }
    return await response.json();
};


export const deleteExam = async (classId, examId, token) => {
    const response = await fetch(`${API_BASE_URL}/api/classes/${classId}/exams/${examId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
    if (!response.ok) throw new Error('Failed to delete exam');
    return response.json();
};

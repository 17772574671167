import React from "react";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";

const buttonStyle = {
  backgroundColor: "#45d831",
  color: "#edfcf9",
  border: "none",
  borderRadius: "5px",
  padding: "2vw", // Use viewport width for dynamic padding
  margin: "1vw 1vw", // Margin based on viewport width
  opacity: 0.7,
  width: "20vw", // Width as a percentage of the viewport width
  height: "10vh", // Height as a percentage of the viewport height
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  transition: "background-color 0.3s",
  "&:hover": {
    backgroundColor: "#9dee17",
    opacity: 1,
  },
};

const iconStyle = {
  color: "#edfcf9",
  fontSize: "6vw", // Icon size based on viewport width
};

const TooltipButton = ({ icon, onClick, tooltip }) => {
  return (
    <OverlayTrigger
      key={tooltip}
      placement="top"
      overlay={<Tooltip>{tooltip}</Tooltip>}
    >
      <Button onClick={onClick} style={buttonStyle}>
        <i className={`fas fa-${icon}`} style={iconStyle}></i>
      </Button>
    </OverlayTrigger>
  );
};

export default TooltipButton;

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faPlus } from "@fortawesome/free-solid-svg-icons";
import ClassManagement from "../components/ClassManagement";
import Footer from "../components/Footer";
import { fetchClassesAndCredits, createClass } from "./apiUtils"; // Ensure correct path
import "bootstrap/dist/css/bootstrap.min.css";

const DashboardPage = () => {
  const [user, setUser] = useState({ credits: 0, classes: [] });
  const token = localStorage.getItem("token");

  useEffect(() => {
    const loadData = async () => {
      if (!token) {
        console.error("No token available.");
        return; // Early return if no token is available
      }
      console.log("Token used for request:", token); // Confirming token is retrieved
      try {
        const userData = await fetchClassesAndCredits(token);
        console.log("User data received:", userData); // Log received user data
        setUser(userData);
      } catch (error) {
        console.error("Failed to fetch user details:", error);
      }
    };
    loadData();
  }, [token]); // Ensure token is stable, otherwise useEffect may loop endlessly

  const handleAddClass = async () => {
    if (!token) {
      console.error("Attempt to add class without token");
      return; // Ensure no operation when there's no token
    }
    const className = `New Class ${user.classes.length + 1}`;
    console.log("Creating new class:", className);
    try {
      const newClass = await createClass(className, token);
      setUser((prevUser) => ({
        ...prevUser,
        classes: [...prevUser.classes, newClass],
      }));
      console.log("Class added successfully:", newClass);
    } catch (error) {
      console.error("Error creating class:", error);
    }
  };

  return (
    <Container
      fluid
      style={{
        minHeight: "100vh",
        backgroundColor: "#edfcf9",
        padding: "20px",
      }}
    >
      <Row className="align-items-center mb-3">
        <Col xs={3} md={3}>
          <h1 style={{ color: "#052423", textAlign: "left" }}>Dashboard</h1>
        </Col>
        <Col xs={3} md={3} className="text-center"></Col>
        <Col
          xs={6}
          md={6}
          className="d-flex justify-content-end align-items-center"
        >
          <FontAwesomeIcon
            icon={faUser}
            style={{ color: "#45d831", fontSize: "24px", marginRight: "10px" }}
          />
          <span
            style={{ color: "#052423", fontSize: "24px", marginRight: "10px" }}
          >
            Credits: {user.credits}
          </span>
          <Button
            variant="success"
            className="rounded-circle p-0"
            style={{ width: "40px", height: "40px" }}
            onClick={() => (window.location.href = "/billing")}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            variant="primary"
            onClick={handleAddClass}
            className="m-2"
            style={{ backgroundColor: "#005F73" }}
          >
            <FontAwesomeIcon icon={faPlus} /> Add Class
          </Button>
          <ClassManagement user={user} setUser={setUser} token={token} />
        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

export default DashboardPage;

import React from "react";
import { Accordion, Button, InputGroup, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserGraduate, faTrash } from "@fortawesome/free-solid-svg-icons";
import { updateClass, deleteClass } from "../pages/apiUtils";
import ExamManagement from "./ExamManagement";

const ClassManagement = ({ user, setUser, token }) => {
  const handleNameChange = async (classId, event) => {
    event.persist(); // This makes sure the event isn't pooled in React. This line can sometimes be necessary.
    const newName = event.target.value; // Make sure this line is correct.

    if (!newName.trim()) {
      console.error("Class name cannot be empty");
      return;
    }

    try {
      const updatedClass = await updateClass(classId, newName, token);
      console.log("Class updated:", updatedClass);
      setUser((prevUser) => ({
        ...prevUser,
        classes: prevUser.classes.map((cls) =>
          cls._id === classId ? updatedClass : cls
        ),
      }));
    } catch (error) {
      console.error("Failed to update class name:", error);
    }
  };

  const handleDeleteClass = async (classId) => {
    if (
      window.confirm(
        "Are you sure you want to delete this class? This action cannot be undone."
      )
    ) {
      try {
        await deleteClass(classId, token);
        console.log("Deleting class ID:", classId);
        setUser((prevUser) => ({
          ...prevUser,
          classes: prevUser.classes.filter((cls) => cls._id !== classId),
        }));
      } catch (error) {
        console.error("Error deleting class:", error);
      }
    }
  };

  const updateClassState = (classId, exam, action) => {
    setUser((prevUser) => {
      const updatedClasses = prevUser.classes.map((cls) => {
        if (cls._id === classId) {
          switch (action) {
            case "add":
              return { ...cls, exams: [...cls.exams, exam] };
            case "update":
              return {
                ...cls,
                exams: cls.exams.map((ex) => (ex._id === exam._id ? exam : ex)),
              };
            case "delete":
              const filteredExams = cls.exams.filter((ex) => ex._id !== exam);
              console.log(`Exams after deletion:`, filteredExams);
              return { ...cls, exams: filteredExams };
            default:
              return cls;
          }
        }
        return cls;
      });
      console.log(`Classes after update:`, updatedClasses);
      return { ...prevUser, classes: updatedClasses };
    });
  };

  return (
    <Accordion defaultActiveKey="0">
      {user.classes.map((cls, index) => (
        <Accordion.Item eventKey={`${index}`} key={cls._id}>
          <Accordion.Header>
            <InputGroup>
              <Button variant="link">
                <FontAwesomeIcon icon={faUserGraduate} />
              </Button>
              <FormControl
                defaultValue={cls.name}
                onBlur={(e) => handleNameChange(cls._id, e)} // Make sure you're passing the event object 'e'
                aria-label="Class name"
              />
              <Button
                variant="outline-danger"
                onClick={() => handleDeleteClass(cls._id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </InputGroup>
          </Accordion.Header>
          <Accordion.Body>
            <ExamManagement
              classId={cls._id}
              exams={cls.exams}
              token={token}
              updateClassState={updateClassState}
            />
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default ClassManagement;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Spinner, Alert } from "react-bootstrap";
import StepOne from "./1StepOne";
import StepTwo from "./2StepTwo";
import StepThree from "./3StepThree";
import { API_BASE_URL } from "../apiConfig";

function UploadPage() {
  const { classId, examId } = useParams();
  const [step, setStep] = useState(1);
  const [modelImages, setModelImages] = useState([]);
  const [studentImages, setStudentImages] = useState([[]]);
  const [responseContent, setResponseContent] = useState("");
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState("");
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (examId) {
      console.log(`Fetching exam data for class ${classId} and exam ${examId}`);
      fetch(`${API_BASE_URL}/exams/class/${classId}/exam/${examId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("Exam data received:", data);
          if (data.modelImages) setModelImages(data.modelImages);
          if (data.studentSubmissions)
            setStudentImages(data.studentSubmissions.map((sub) => sub.images));
          if (data.resultPdfUrl) setResponseContent(data.resultPdfUrl);
          setStep(1); // Always start with step 1
        })
        .catch((err) => {
          console.error("Error fetching exam details:", err);
          setError("Failed to fetch exam data.");
        });
    }
  }, [examId, classId, token]);

  const handleUpload = async () => {
    setUploading(true);
    console.log("Starting upload...");
    const formData = new FormData();
    modelImages.forEach((file, index) => {
      if (typeof file !== "string") {
        console.log(`Appending model image ${index}:`, file);
        formData.append("modelImages", file);
      }
    });
    studentImages.forEach((group, sIndex) => {
      group.forEach((file, fIndex) => {
        if (typeof file !== "string") {
          console.log(`Appending student image ${sIndex}-${fIndex}:`, file);
          formData.append(`studentImages[${sIndex}][]`, file);
        }
      });
    });

    try {
      console.log("Sending upload request...");
      const response = await fetch(
        `${API_BASE_URL}/exams/class/${classId}/upload-exam/${examId}`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log("Upload response:", data);
      setUploading(false);
      if (data.exam) {
        setResponseContent(data.exam.resultPdfUrl);
        setStep(3); // Move to step 3 after successful upload and response
      }
    } catch (error) {
      console.error("Error during upload:", error);
      setError("Failed to upload files. Please try again.");
      setUploading(false);
    }
  };

  const handleDeleteImage = async (imageUrl, type, studentIndex) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/exams/class/${classId}/exam/${examId}/images`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ imageUrl, type, studentIndex }),
        }
      );
      const data = await response.json();
      console.log("Delete response:", data);

      if (type === "model") {
        setModelImages((prevImages) =>
          prevImages.filter((url) => url !== imageUrl)
        );
      } else if (type === "student" && studentIndex !== undefined) {
        setStudentImages((prevImages) => {
          const updatedImages = [...prevImages];
          if (updatedImages[studentIndex]) {
            updatedImages[studentIndex] = updatedImages[studentIndex].filter(
              (url) => url !== imageUrl
            );
            if (updatedImages[studentIndex].length === 0) {
              updatedImages.splice(studentIndex, 1);
            }
          }
          return updatedImages;
        });
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  return (
    <Container className="upload-page">
      {uploading && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Uploading...</span>
        </Spinner>
      )}
      {error && <Alert variant="danger">{error}</Alert>}
      {step === 1 && (
        <StepOne
          modelImages={modelImages}
          onAddModelImage={setModelImages}
          onNext={() => setStep(2)}
          onDeleteImage={handleDeleteImage}
        />
      )}
      {step === 2 && (
        <StepTwo
          studentImages={studentImages}
          onAddStudentImage={setStudentImages}
          onUpload={handleUpload}
          onDeleteImage={handleDeleteImage}
          onAddStudent={() => setStudentImages([...studentImages, []])}
        />
      )}
      {step === 3 && <StepThree latexContent={responseContent} />}
    </Container>
  );
}

export default UploadPage;

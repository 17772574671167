import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const useAuth = () => {
  // Retrieve the token from localStorage
  const token = localStorage.getItem("token");
  // Check if token exists and assume user is authenticated if it does
  // Note: You might want to add more sophisticated checks here depending on your security needs
  const isAuthenticated = token !== null;
  return isAuthenticated;
};

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = useAuth();

  if (!isAuthenticated) {
    // Redirect them to the /login page if not authenticated
    return <Navigate to="/login" replace />;
  }

  // Render children if provided, otherwise render the Outlet (for nested routes)
  return children ? children : <Outlet />;
};

export default ProtectedRoute;
